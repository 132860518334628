.input-title-pipe-id {
  margin: 10px 0 10px 10px;
  font-weight: bolder;
}

.pipe-id-label {
  margin: 10px 0 10px 10px;
  font-weight: bolder;
}

.pipe-id-fieldname {
  width: 75px;
  margin: 0 0 10px 10px;

  .input-field {
    background-color: #f0f0f0;
    border-radius: 0;
  }
}

.natural-gas-label,
.custom-fluid-label,
.custom-input-label {
  margin: 10px 0 10px 10px;
  font-weight: bolder;
}

.natural-gas-fieldname,
.custom-fluid-fieldname,
.custom-fluid-fieldname5,
.custom-input-fieldname {
  margin: 0 0 10px 10px;
  width: 300px;
  font-size: 16px !important;

  #natural-gas-name-inputfield,
  #custom-fluid-name-inputfield,
  #custom-input-field {
    background-color: #f0f0f0;
    border-radius: 0;
  }
}

.composition-input-label {
  margin: 20px 0 10px 10px;
}
.composition-input-fieldname {
  margin: 0 0 10px 10px;
  width: 120px;


  .composition-input-form-control {
    background-color: #f0f0f0;
    border-radius: 0;
  }

  .input-group {
    display: inline;
  }

  .post-script {
    margin-left: 5px;
  }
}

.nav-input-form-control {
  #gas-pressure,
  #gas-temperature {
    border: 2px solid #c4ced1;
    border-radius: 0;
    width:100%;
    height: 35px;
    font-size: 16px;
  }
}
.natural-gas-fieldname {
  @media  screen and (min-width: 320px) and (max-width: 390px) {
    margin: 0 0 10px 10px;
    width:  100%;
	}
  @media screen and (min-width:390px) and (max-width: 576px)  {
    margin: 0 0 10px 10px;
    width:93%;
}
@media (min-width: 576px) and (max-width: 1024px)    {
  margin: 0 0 10px 10px;
  width: 100%;
} 
@media (max-width: 450px)   {
  margin: 0 0 10px 10px;
  width:  100%;
} 
}

.custom-fluid-fieldname5 {

  @media  screen and (min-width: 200px) and (max-width: 320px) {
    margin: 0 0 10px 10px;
    width: 160px;
	}
  @media  screen and (min-width: 320px) and (max-width: 390px) {
    margin: 0 0 10px 10px;
    width: 230px;
	}
  @media screen and (min-width:390px) and (max-width: 600px)  {
    margin: 0 0 10px 10px;
    width: 250px;
}
@media screen and (min-width: 450px)  {
  margin: 0 0 10px 10px;
  width: 416px;
} 
}

.custom-fluid-fieldname, .custom-input-fieldname {

  @media  screen and (min-width: 200px) and (max-width: 320px) {
    margin: 0 0 10px 10px;
    width: 160px;
	}
  @media  screen and (min-width: 320px) and (max-width: 390px) {
    margin: 0 0 10px 10px;
    width: 230px;
	}
  @media screen and (min-width:390px) and (max-width: 600px)  {
    margin: 0 0 10px 10px;
    width: 250px;
}
@media screen and (min-width: 450px)  {
  margin: 0 0 10px 10px;
  width: 300px;
} 
}
.uom-input-form-control {
  width: 75px;
  input.uom-input-form-control {
    border: 2px solid #c4ced1;
    border-radius: 0;
    height: 35px;
    font-size: 16px;
    padding-left: 5px;
    padding-right: 3px;
    background-color: #f0f0f0;
  }
}
.my-sizings-input-field {
  width: 150px;

  input.my-sizings-input-field {
    border: 2px solid #c4ced1;
    border-radius: 0;
    height: 50px;
    font-size: 16px;
    padding-left: 5px;
    background-color: #f0f0f0;
    margin: 6px;
  }
}
.react-datepicker-wrapper {
  height: 50px;
  .my-sizing-date-input {
    border: 2px solid #c4ced1;
    background-color: #f0f0f0;
    height: 50px;
    font-size: 16px;
    width: 120px;
    margin: 6px;
    border-radius: 0;
    padding-left: 8px;
  }
}


