@use '../../styles/variables' as v;

.dropdown-toggle:after {
  float: right;
  display: inline-block;
  position: relative;
  height: 8px;
  width: 8px;
  bottom: -6px;
  content: '';
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  border: 0 solid v.$emerson-green;
  border-right-width: 2px;
  border-bottom-width: 2px;
  transition: 0.4s;
  margin-right: 5px;
}

.dropdown-toggle[aria-expanded="true"]:after {
  transform: rotate(225deg);
  transition: 0.4s;
}

button.btn-dropdown {
  width: 100%;
}

.transmitter-dropdown {
  margin: 6px 6px 6px 10px;
  border-radius: 0;
  background-color: #f0f0f0;
  max-width: 416px;
  width: 416px;
  height: 50px;
  border: 2px solid v.$emerson-green;
  color: black;
  padding-left: 15px;
  padding-right: 5px;

  .dropdown-text {
    display: inline-block;
    float: left;
    width: 85%;
    white-space: nowrap;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    letter-spacing: 0.05px;
  }
}
.btn-dropdown {
  margin: 6px 6px 6px 10px;
  border-radius: 0;
  background-color: #f0f0f0;
  max-width: 300px;
  height: 50px;
  border: 2px solid v.$emerson-green;
  color: black;
  padding-left: 15px;
  padding-right: 5px;

  .dropdown-text {
    display: inline-block;
    float: left;
    width: 85%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    letter-spacing: 0.05px;
  }
}

.btn-dropdown-small {
  margin: 0;
  border-radius: 0;
  background-color: #f0f0f0;
  width: 160px;
  height: 30px;
  color: black;
  padding-left: 15px;
  padding-right: 5px;

  .dropdown-text {
    display: inline-block;
    float: left;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    letter-spacing: 0.05px;
  }
}

.btn-dropdown-my-sizings {
  margin: 6px;
  border-radius: 0;
  background-color: #f0f0f0;
  height: 50px;
  color: black;
  padding-left: 15px;
  padding-right: 5px;

  .dropdown-text {
    display: inline-block;
    float: left;
    width: 85%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    letter-spacing: 0.05px;
  }
}


.btn-dropdown:disabled,
.btn-dropdown-small:disabled {
  background-color: #f0f0f0;
  cursor: not-allowed;
  border: none;

}

.dropdown-menu {
  line-height: 36px;
  font-size: 14px;
  max-height: 360px;
  overflow-y: auto;

  .dropdown-item {
    line-height: 20px;
    padding-top: 9px;
    padding-bottom: 9px;
  }

  .dropdown-item:active {
    color: v.$emerson-green;
    background-color: #f0f0f0;
  }

  .dropdown-item.active {
    color: v.$emerson-green;
    background-color: #f0f0f0;
  }
  
  .dropdown-item:focus {
    color: v.$emerson-green;
    background-color: #dfe5ed;
  }
 
}

.dropdown-menu a:hover {
  color: v.$emerson-green;
}

.dropdown-title {
  font-weight: bolder;
  margin: 10px 6px 6px 10px;
}

.dropdown-title-left {
  float: left;
  width: 100px;
  margin: 15px 6px 6px 6px;
}

#composition-method-dropdown {
  width: 600px !important;
}

.searchBox{
  width: 93%!important;
}
.form-control:focus{ 
  border-color: #d9d9d9;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 0%);
}



// disabled arrow dropdown alignment
.btn-dropdown:disabled.dropdown-toggle:after {
  bottom: 0;
}

@media (min-width:576px) and (max-width: 688px){
  .btn-dropdown-small{
    margin-left: 8px;
  }
}
@media (min-width:200px) and (max-width: 576px){
  .btn-dropdown-small{
    margin-left: 8px;
  }
}

/* Mobile */
@media (max-width: 767px) {
  .dropdown-menu {
    max-width: 96%;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .btn-dropdown-small{
    margin-left: 8px;
  }
}

@media (min-width: 415px) and (max-width:576px) {
  .float-right {
    float: left!important;
  }
  .btn-dropdown-small{
    margin-left: 8px;
  }
}
/* Small Mobile */
@media (max-width: 415px) {
  .btn-dropdown {
    margin: 6px 0;
    max-width: 250px;
  }
  .btn-dropdown-small{
    margin-left: 8px;
  }
  
}
@media (max-width: 350px) {
  .float-right {
    float: left!important;
  }
  .btn-dropdown {
    margin: 6px 0;
    max-width: 230px;
  }
  .btn-dropdown-small{
    margin-left: 8px;
  }
}
