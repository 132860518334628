.outerText {
    position: absolute;
    top: 55%;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    color: #114885;
}

.loaderDiv{
    text-align: center;
}
.outerloaderImg{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 60px;
    width: 60px;
}
.innerLoaderImg{
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    height: 60px;
    width: 60px;
}
.innerText {
    position: absolute;
    top: 24%;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    color: #114885;
}