@use '../../styles/variables' as v;


.SubHeader{
  font-weight: 600;
  text-align: center;
  font-size: 14px;
}


