@use '../../styles/variables' as v;
//global changes
#requisitionListContent > div > div > div.modal-header > h3 > p {
  font-weight: bold;
}

#requisitionListContent > .modal-dialog > .modal-content {
  width: 575px;
}

.modal-header{
  display: block;
}

.row.emr-margin.tagActionDiv {
  display: block;
}

div#requisitionListsSaveButton {
  margin: auto;
}
.modal-header-fpa-trans {
  display: flex;
  font-size: 15px;
  padding-top: 7px;
  padding-bottom: 5px;

  .modal-title > span {
    color: v.$emerson-green;
    font-size: 20px;
  }
 
}
.modal-header-fpa {
  display: flex;
  font-size: 20px;
  .modal-title > span {
    color: #d31245;
  }
  .modal-title-confirmation > span {
    color: v.$emerson-green;
  }

}
.modal-body-fpa {
  p.main-text {
    font-size: 16px;
    font-weight: bold;
  }
  p.info-text {
    font-size: 14px;
  }
}

.modal-footer {
  border: none;
}
.modal-backdrop {
  background-color: #0000005e;
}
.modal-message{
  font-size: 14px;
}

@media (max-width:400px) and (min-width: 200px){
  .modal-content{
   width:100% 
  }
  }
