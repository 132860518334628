@use '../../styles/variables' as v;

.custom-checkbox {
  opacity: 0;
  position: absolute;

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0 .5rem 0 20px;
    line-height: 18px;
  }

  & + label:before {
    content: '';
    margin-right: 5px;
    display: inline-block;
    width: 13px;
    height: 13px;
    border: 1px solid #c0c0c0;
    position: absolute;
    left:0;
    top:2px;
  }

  &:hover:enabled + label:before {
    background-color: grey;
  }

  &:disabled + label {
    color: #b8b8b8;
  }

  &:checked + label:after  {
    content: '';
    position: absolute;
    left: 1px;
    top: 6px;
    width: 2px;
    height: 2px;
    box-shadow:
      2px 0 0 v.$emerson-green,
      4px 0 0 v.$emerson-green,
      4px -2px 0 v.$emerson-green,
      4px -4px 0 v.$emerson-green,
      4px -6px 0 v.$emerson-green,
      4px -8px 0 v.$emerson-green;
    transform: rotate(45deg);
  }

  label {
    cursor: pointer;
  }
}

.application-requirements {
  .checkbox-title {
    margin-left: 10px;
  }
  .meter-styles {
    margin-left: 0;
  }
}

.sizing-results-table .custom-checkbox {
  position: relative;
}