.download-button {
  float: right;
  border-radius: 0;
  height: 50px;
  font-size: 12px;
  width: 140px;
  letter-spacing: .5px;
  background-color: #00aa7e;
  border: none;
  color: white;
  font-weight: bold;
}
.comparison-popout-page {
  font-family: Arial, serif;
  margin-left: 20px;
}

h1.content-title {
  font-weight: normal;
  font-size: 30px;
  display: inline-block;
}
.process-table {
  table{
    display:none
  }
  border-collapse: collapse;
  font-size: 14px;
  tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
}
tr {
  border-top: 1px solid #ddd;
}
th {
  max-width: 120px;
  text-align: left;
}
td {
  text-align: center !important;
  padding: 8px;
}
}
.comparison-table {
  width: 95%;
  height: 100%;
  border-collapse: collapse;
  font-size: 14px;
  tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
}
tr {
  border-top: 1px solid #ddd;
}
th {
  max-width: 120px;
  text-align: left;
}
td {
  padding: 8px;
  text-align: center;
}
}
.imageCenter{
 
  position: relative;
}


@media (max-width: 720px) {

  .download-button {
    margin-bottom: 30px;
  }
}