@use '../../styles/variables' as v;

.showMoreBorder {
  border: 1px solid #c4ced1;
  border-radius: 2px;
  margin-top: 18px;
  margin-left: 0px;
  margin-bottom: 16px;
}

.showMorePadding {
  padding-top: 10px;
  font-weight: 400;
  font-size: 14px;
  max-width: 91%;
  color: #000;
}

.Labelpadding {
  padding-left: 0px;
}

.ShowMoreHeader {
  font-size: 21px;
  text-transform: capitalize;
}

.ShowMoreSubHeader {
  font-weight: 600;
  font-size: 14px;
}

.installationImage {
  float: right;
  position: relative;
  padding-bottom: 31px
}

.installation-table {
  font-size: smaller;
  padding-top: 33px;

  tr {
    text-align: center;
  }
}

.green {
  color: lime;
}

.download-button-installation {
  float: right;
  border-radius: 0;
  height: 50px;
  font-size: 12px;
  width: 230px;
  letter-spacing: .5px;
  background-color: #00aa7e;
  border: none;
  color: white;
  font-weight: bold;
}

// .installation-table {
//   font-size: 1em;
//   border-collapse: separate;
//   border-spacing: 0;
//   //overflow: auto;
//   padding-top: 33px;
//   table-layout: auto;
//   width: 100%;
//   border-bottom-width: inherit;
//   white-space: nowrap;
//   background-color: #fff;

//   tr {
//     text-align: center;
//     //font-size: 12px;
//     font-weight: 400;
//   }
//   td {
//     padding: 8px;
//   }
//   th{
//     border-bottom-width: inherit;  
//   padding: 1px;      }
//   .wrap {
//     overflow-wrap: break-spaces;
//   }
//   .nowrap {
//     overflow-wrap: inherit;
//     white-space: inherit;
//   }
//   .header-content {
//     display: inline-block;
//     vertical-align: top;
//   }
//   .span_centered {
//     position: relative;
//   }

//   tbody > tr > td:nth-child(1),
//   thead > tr:nth-child(1) > th:nth-child(1),
//   thead > tr:nth-child(2) > th:nth-child(1) {
//     background-color: #fff;
//     left: 0;
//     z-index: 2;
//     border: 1px solid #ddd;
//   }
//   tbody > tr > td:nth-last-child(1) {
//     padding: 3px;
//   }

//   .description-width {
//     //font-size: 11px;
//     width: 260px;
//     white-space: pre-wrap;
//   }

//   tbody > tr:hover > td:nth-child(1) {
//     background-color: #ececec;
//   }

//   tbody > tr.selected-row > td:nth-child(1) {
//     background-color: v.$background-grey2;
//   }

//   thead > tr:nth-child(1) > th:nth-child(1) {
//     width: 100px;
//   }

//   .sub-text-uom {
//     //font-size: 11px;
//   }
//   .selected-row {
//     background-color: v.$background-grey2;
//   }
//   thead > tr:nth-child(2) > th:nth-child(1),
//   thead > tr:nth-child(2) > th {
//     border-top: none;
//     white-space: pre-wrap;

//   }
// }
.install-table {

  thead>tr:nth-child(1)>th:nth-child(1),
  thead>tr:nth-child(2)>th:nth-child(1),
  thead>tr:nth-child(3)>th:nth-child(1),
  thead>tr:nth-child(4)>th:nth-child(1),
  thead>tr:nth-child(1)>th:nth-child(2),
  thead>tr:nth-child(2)>th:nth-child(2),
  thead>tr:nth-child(3)>th:nth-child(2),
  thead>tr:nth-child(4)>th:nth-child(2) {
    border-bottom: none;
  }

  thead>tr:nth-child(2)>th {
    font-size: 12px;
    border-top: 1px grey;
    width: 260px;
    white-space: pre-wrap;
  }

  thead>tr:nth-child(1)>th:nth-child(6) {
    border-bottom: 2px solid #dee2e6
  }

  thead>tr:nth-child(4)>th:nth-child(3) {
    border-bottom: 2px solid #dee2e6
  }

  tbody>tr>td {
    padding-bottom: 1px;
  }
}

@media (max-width: 720px) {

  .download-button-installation {
    margin-bottom: 30px;
  }
}