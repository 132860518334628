@use 'variables' as v;

.App {
  text-align: center;
  font-family: Arial, serif;
  margin: 7px 0;
  hr {
    border-style: solid none;
    border-color: #c0c0c0;
    border-width: 0.5px 0;
    width: 98%;
  }
  .border-error {
    border: 2px solid v.$error-red !important;
  }
  .LineBottom{
    border-bottom: 1px solid #c4ced1;
    margin-bottom: 20px;
  }
  .OnlineCircle {
    height: 10px;
    width: 10px;
    background-color:  v.$emerson-green;
    border-radius: 50%;
    display: inline-block;
  }
  .OfflineCircle {
    height: 10px;
    width: 10px;
    background-color: v.$error-red;
    border-radius: 50%;
    display: inline-block;
  }
  .header-sticky {
    position: sticky;
  }
  .offline_header {
    position: relative;
    top: 18px;
    float: right;
    right: 39px;
  }
  .HeaderList .list-group-item {
    padding: 0px;
    border: 0px;
  }
  .HeaderList {
    font-size: 15px;
    text-align: end;
  }
  .AppHeader {
    padding: 0 3rem;
  }
  .HeaderList .nav-link {
    padding: 0;
    color: v.$emerson_green;
  }

  .HeaderDropDown .dropdown-toggle:after {
    content: none;
  }
  .HeaderDropDown .dropdown-menu {
    left: -7em;
    padding: 0;
  }
  .HeaderDropDown .dropdown-item {
    text-align: end;
    line-height: 1.7em;
    padding-left: 33px;
    padding-right: 5px !important;
    padding-top: 0.1rem;
    padding-bottom: 0.4rem;
    white-space: break-spaces;
  }
  #languages-dropdown .dropdown-toggle:after {
    content: none;
  }
  .header-sticky,
  .header {
    text-align: left;
    top: 0;
    z-index: 5;
    background: #ffffff;

    .sizing-and-selection-header {
      max-width: 1180px;
      margin: 0 auto;

      .page-heading {
        @media (min-width:200px) and (max-width: 576px){
          font-size: 27px;
        }
        font-size: 36px;
      }

      .page-description {
        @media (min-width:200px) and (max-width: 576px){
          font-size: 12px;
        }
        font-size: 14px;
      }

      .navigation {
        @media (min-width:200px) and (max-width: 576px){
          text-align: inherit;
          font-size: 12px;
        }
        text-align: right;
        font-size: 14px;

        a {
          color: #000;
        }
      }

      .button-nav {
        @media (min-width:200px) and (max-width: 576px){
          text-align: right;
          display: flex;
        }
        text-align: right;
      }

      .btn-primary {
        border: none;
        background-color: grey;
      }
    }
  }
  .languageText {
    color: v.$emerson-green;
  }
  .sizing-and-selection {
    z-index: 3;
    text-align: left;

    .test-body {
      height: 900px;
    }

    .green-nav-bar {
      height: 70px;
      background-color: v.$emerson-green;
      width: 100%;
      margin: 20px 0 20px 0;

      .btn-primary {
        background-color: v.$emerson-green !important;
      }

      .nav-button {
        height: 70px;
        width: 225px;

        cursor: pointer;
        letter-spacing: 0.08em;
        font-weight: 600;
        font-size: 12px;
        padding-top: 27px;
        text-align: center;
        border-radius: 0;
        background-color: v.$emerson-green;
        border-color: v.$emerson-green;
        text-transform: uppercase;
      }
      .nav-button:active {
        border-bottom: 6px solid #fff;
        background-color: darken(v.$emerson-green, 5%);
      }
      .nav-button.active {
        border-bottom: 6px solid #fff;
        background-color: darken(v.$emerson-green, 5%) !important;
      }
      .nav-button:hover {
        background-color: darken(v.$emerson-green, 5%) !important;
      }
    }


    @media (max-width: 1199px) {
      .green-nav-bar {
        height: 70px;
        background-color: v.$emerson-green;
        width: 100%;
        margin: 20px 0 20px 0;
  
        .btn-primary {
          background-color: v.$emerson-green !important;
        }
  
        .nav-button {
          height: 70px;
          width: 100%;
  
          cursor: pointer;
          letter-spacing: 0.08em;
          font-weight: 600;
          font-size: 12px;
          padding-top: 27px;
          text-align: center;
          border-radius: 0;
          background-color: v.$emerson-green;
          border-color: v.$emerson-green;
          text-transform: uppercase;
        }
        .nav-button:active {
          background-color: darken(v.$emerson-green, 5%);
        }
        .nav-button.active {
          background-color: darken(v.$emerson-green, 5%) !important;
        }
        .nav-button:hover {
          background-color: darken(v.$emerson-green, 5%) !important;
        }
        .btn-group-toggle{
          flex-direction: column;
          width: 100%;
        }
      }  
      
    }


    .title {
      margin: 15px 0 15px 10px;
      font-weight: bolder;
      font-size: 21px;
    }

    .selection-container {
      background-color: #fff;
      margin: 0 20px 10px 20px;
      padding: 25px 15px 20px 15px;
      line-height: 14px;
      border: v.$thin-border-grey;
      border-radius: 4px;
    }
    .selection-container-report {
      background-color: #fff;
      margin: 0 20px 10px 20px;
      padding: 25px 15px 20px 15px;
      line-height: 14px;
      border: v.$thin-border-grey;
      border-radius: 4px;
    }
    .update-pref-text {
      font-weight: bold;
      font-size: 16px;
      margin: 0 0 20px 5px;
    }
    .pref-label,
    .uom-label {
      font-size: 12px;
      margin: 10px 5px;
    }

    .pref-dropdown {
      width:100%;
      background-color: v.$background-grey;
      border-radius: 0;
      height: 35px;

      .dropdown-text {
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0;
        letter-spacing: 0.05px;
      }
    }

    .uom-dropdown {
      width: 100%;
      background-color: v.$background-grey;
      border-radius: 0;
      height: 35px;
    }

    .pref-row,
    .uom-row {
      min-height: 50px;
    }

    .uom-conversion {
      height: 330px;
      padding: 20px;

      .uom-panel {
        height: 100%;
        background-color: #fff;
        border: v.$thin-border-grey;
        border-radius: 4px;
        padding: 20px;

        .column-heading {
          font-weight: bold;
          margin-bottom: 25px;
        }
        .uom-flow-rate-field {
          width: 30px;
        }
        .uom-button-section {
          height: 100%;
          margin-top: 100%;
          text-align: center;
        }
      }
    }

    .my-sizings {
      max-width: 1180px;
      margin: 0 auto;

      // set calendar to show above table header
      .react-datepicker-popper {
        z-index: 999;
      }

      .my-sizings-page {
        background-color: v.$background-grey;
        padding: 20px;

        .search-results-title {
          font-weight: bold;
          font-size: 16px;
          margin: 18px 0 2px 0;
        }
        .actions-tool-text {
          text-align: right;
          margin-bottom: 10px;
        }
        .my-sizings-panel {
          height: 100%;
          background-color: #fff;
          border: v.$thin-border-grey;
          border-radius: 4px;
          padding: 20px;

          .input-section {
            height: 100%;
            border: 2px solid lightgray;
            border-radius: 50px 50px 0 0;
            padding: 10px 30px;
            position: relative;

            .date-label {
              padding: 15px 0 10px 6px;
              float: left;
            }

            .btn-calendar-icon {
              margin: 0;
              padding: 0;
            }
          }

          .results-section {
            height: 100%;
            border: 2px solid lightgray;
            border-radius: 0;
            padding: 10px 30px;
            position: relative;

            .sort-by-section {
              text-align: right;
            }
            .my-sizings-results-table {
              border-collapse: separate;
              border-spacing: 0;
              table-layout: auto;
              width: 100%;
              background-color: #fff;
              max-height: 500px;
              min-height: 250px;
              display: table-row-group;
              overflow-y: scroll;
              overflow-x: scroll;

              .sort-arrow {
                vertical-align: top;
                display: inline-block;
              }
              thead {
                tr {
                  text-align: center;
                  text-transform: capitalize;
                  height: 30px;
                  position: sticky;
                  
                  th {
                    margin: 10px 15px;
                    padding: 10px 15px;
                    font-weight: bold;
                    font-size: 14px;
                    position: sticky;
                    position: -webkit-sticky;
                    top: 0;
                    z-index: 2;
                    background: white;
                    min-width: 80px;

                    .wrap {
                      white-space: pre-wrap;
                    }
                    .left {
                      width: 90%;
                      float: left;
                    }
                    // for the sort arrows
                    .right {
                      width: 10%;
                      float: left;
                    }
                  }
                }
              }
              tbody {
                tr {
                  text-align: center;
                  height: 30px;
                  td {
                    font-weight: normal;
                    font-size: 12px;
                    white-space: pre-wrap;

                    label {
                      margin-bottom: 0;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
 
    .basic-page {
      background-color: v.$background-grey;
    }
    .modal-title-confirmation {
      margin-bottom: 0;
      line-height: 1.5;
      font-size: 17px;
  }
  
    .flowlogin-page {
      .col-background {
        padding: 20px;
        text-align: center;
        background-color: v.$background-grey;
      }
    }
    .testColor {
      color: red !important;
      margin-right: 6em;
    }

    .advanced-page {
      margin-top: 210px;
      .col-background {
        background-color: v.$background-grey;
      }
      .process-variables,
      .fluid-selection {
        padding-right: 20px;
      }
    }

    .basic-page,
    .advanced-page,
    .preferences,
    .toolkit-upload,
    .uom-conversion {
      max-width: 1180px;
      margin: 0 auto;

      ul {
        list-style: none;
        margin: 0;
      }

      .right-nav-column {
        margin-right: 0;
      }

      .total-mole,
      .molecular-weight {
        margin-top: 20px;
        float:right;
      }

      .total-mole-result,
      .molecular-weight-result {
        @media (min-width: 300px)and (max-width:568px) {
          text-align: right;
        }
        margin-top: 20px;
        margin-right: auto;
      }

      .btn-mole-normalize {
        width: 140px;
        border-radius: 0;
        letter-spacing: 0.5px;
        font-size: 14px;
        margin: 10px 5px;
        background-color: v.$emerson-green;
        border: none;
        float: right;
      }

      .btn-mole-normalize:hover,
      .btn-mole-normalize:after {
        background-color: darken(v.$emerson-green, 5%) !important;
      }

      .available-components,
      .selected-components {
        @media (min-width: 768px)and (max-width: 1024px) {
          min-width: 100%;
          min-height: 826px;
        }
        border: v.$thin-black-border;
        min-height: 786px;
        min-width: 230px;
        margin-top: 10px;
        border-radius: 0;
        width: 100%;
        padding: 0;
        Button {
          border: none;
        }
      }
      // border: 2px solid red;

      .selected-components {
        max-height: 500px;
        overflow: auto;
        .selected-header {
          border-bottom-color: #000;
        }
        .mole-header {
          float: right;
          @media (min-width:768px){
            margin-right: -20px;
          }
          
        }

        .input-group-append {
          width: 100% !important;
          display: block;
          border-radius: 0;
          .list-group-item {
            border-radius: 0;
            border-bottom: v.$thin-black-border;
            border-left: v.$thin-black-border;
            border-right: none;
            .mole-field :focus{
              box-shadow: none;
              border: 1px solid transparent;
            }
            .mole-field-error {
              float: right;
              width: 65px;
              height: 30px;
              border-radius: 0;
              border: 1px solid red;
            }
            .mole-field {   
              border: 2px solid v.$emerson-green;
              float: right;          
              width: 65px;
              height: 30px;
              border-radius: 0;
            }
          }
        }
      }

      .available-components .active,
      .selected-components .active {
        background-color: v.$background-grey;
        border: none;
        color: #000;
      }

      .available-components-title {
        margin: 15px 0 0 5px;
      }
      .transmitter-modal {
        max-width: 658px;
        margin: 1.75rem auto;
    }
      .transfer-buttons {
        align-content: center;
      }

      .selection-container {
        background-color: #fff;
        margin: 0 20px 10px 10px;
        padding: 25px 15px 20px 15px;
        line-height: 14px;
        border: v.$thin-border-grey;
        border-radius: 4px;

        #pipe-id-units-dropdown:after {
          bottom: 18px;
        }

        #pipe-id-units-dropdown {
          width: 75px;
          border: none;
          height: 33px;
          margin-top: 32px;
        }

        .checkbox-title {
          font-weight: bolder;
          margin-bottom: 15px;
        }

        .pipe-id {
          width: 100px;
          margin: 0 0 10px 10px;
          .pipe-id-field {
            border-radius: 0;
          }
        }

        hr {
          border-style: solid none;
          border-color: #c0c0c0;
          border-width: 0.5px 0;
        }

        .steam-list {
          margin: 20px 0 0 -20px;
        }

        .composition-input {
          display: inline-block;
          .post-script {
            float: left;
          }
        }

        .operating-conditions-heading,
        .fluid-properties-heading,
        .base-reference-conditions-heading {
          font-size: 15px;
          margin-left: 15px;
          margin-top: 10px;
          width: 100%;
        }

        .operating-conditions-table,
        .fluid-properties-table,
        .base-reference-conditions-table {
          margin: 0;
          font-size: 12px;
        }

        .operating-conditions-table-header {
          .op-table-heading-units {
            padding-left: 23px;
            text-transform: uppercase;
          }
          .op-table-heading-norm {
            padding-left: 8px;
            text-transform: uppercase;
          }
          .op-table-heading-min {
            padding-left: 10px;
            text-transform: uppercase;            
          }
          .op-table-heading-max{
            text-transform: uppercase;   
          }
          .op-table-heading-full{
            text-transform: uppercase; 
          }
        }

        .fluid-properties-table-header {
          .fp-table-heading-max {
            padding-left: 15px;
            text-transform: uppercase;   
          }
          .fp-table-heading-norm {
            padding-left: 20px;
            text-transform: uppercase;   
          }
          .fp-table-heading-units {
            padding-left: 10px;
            text-transform: uppercase;   
          }
          .fp-table-heading-min{
            text-transform: uppercase;   
          }
        }

        .base-reference-conditions-table {
          @media (min-width:200px) and (max-width: 576px){
            margin-left: 2px;
          }
          table-layout: auto;
          width: 150px;
          margin-left: 150px;
        }

        .operating-conditions-table th,
        .fluid-properties-table th {
          width: 30px;
          text-align: left;
        }

        .fluid-properties-table {
          margin-left:0px;
        }

        .non-newtonian-checkbox {
          font-size: 12px;
          font-weight: bold;
          margin-top: 10px;
          margin-left: 15px;
        }

        .calculate-density-variation-checkbox {
          margin: 20px 0 10px 20px;
        }

        .k-flow-consistency-index-cp {
          text-align: left;
        }

        .flow-rate-heading,
        .line-press-heading,
        .temp-heading,
        .ambient-heading,
        .fluid-property-heading,
        .gas-property-heading {
          text-align: right;
          width: 30px;
          padding-right: 0rem;
          text-transform: uppercase;
          position: relative;
          top: 0.5rem;
        }
        .flow-rate-heading-small{
          text-align: left;
          font-size: small;
          font-weight: lighter;
          width: 30px;
          padding-right: 0rem;
          text-transform: uppercase;
          position: relative;
          top: 0.5rem;
      }
        .oc-input,
        .fp-input {
          float:left;
          width: 65px;
          height: 30px;
          border-radius: 0;
          background-color: v.$background-grey;
          padding-left: 1px;
        }

        .operating-conditions-table td,
        .fluid-properties-table td {
          padding: 10px 5px 10px 5px;
        }

        .border-not-required {
          border: v.$thicker-border-grey;
        }
        .border-required {
          border: 2px solid v.$emerson-green;
        }
        .border-error {
          border: 2px solid v.$error-red;
        }
        .border-disabled {
          border: none;
        }
        .align-left {
          text-align: left;
        }
        .fp-input {
          float: left;
        }
      }

      // this removes the natural 15px padding
      // from react-bootstrap
      .col-md-12,
      .col-lg-4,
      .col-xl-4 {
        padding-right: 0;
      }
    
      // Right nav list
      .right-nav-selection {
        font-size: 14px;

        .container {
          padding-right: 0;

          ul {
            list-style: none;
            margin: 0;
          }

          .list-group {
            line-height: 24px;

            :hover {
              background-color: v.$background-grey2;
              cursor: pointer;
            }

            .list-group-item {
              border-radius: 0;
              padding: 0;
              background-color: v.$background-grey;

              .inner-list {
                padding: 5px 20px;
                min-height: 40px;

                .indent {
                  padding-left: 20px;
                }

                .sublist {
                  .hide {
                    display: none;
                  }

                  .text-red {
                    color: v.$error-red;
                  }

                  .text-normal {
                    color: #000;
                  }

                  ul {
                    padding-left: 20px;
                  }
                }
              }
            }

            .color-bar {
              height: 100%;
            }

            .bar-green {
              border-left: 15px solid v.$emerson-green;
            }

            .bar-red {
              border-left: 15px solid v.$error-red;
            }
          }
        }
      }

      //basic page footer
      .footer-nav-panel {
        height: 95px;
        width: 100%;
        .sizing-results-button {
          float: right;
          margin: 10px;
        }
        .next-nav-button {
          float: right;
          margin-top: 10px;
        }
        .back-nav-button {
          margin-left :20px;
          margin-top: 10px;
        }
        .hide {
          display: none;
        }
        .show {
          display: inline-block;
        }
      }
    }

    // table sorting arrows
    .icon-up:before {
      content: " \1f851";
    }
    .icon-down:before {
      content: " \1f853";
    }
    .icon-up-down:before {
      content: " \1f859";
    }

    .advanced-page {
      .corrosion-link-text {
        width: 250px;
        font-size: 12px;
        font-weight: bold;
        margin: 0px 0px 0px 15px;
      }

      .sizing-results-field-label {
        font-size: 12px;
        margin-bottom: 0;
      }
      .nowrap {
        white-space: nowrap;
      }
      .sizing-results-button {
        width: 100%;
        text-align: right;
      }
      .sizing-results-header {
        .sizing-results-field {
          width: 130px;
          border-radius: 0;
          background-color: v.$background-grey;
          padding-left: 5px;
        }
        .table-text1 {
          font-weight: bold;
          font-size: 14px;
        }
        .table-text2 {
          font-weight: bold;
          float: left;
          font-size: 14px;
        }

        @media (max-width: 768px) {
          .table-text2 {
            width: 100%;
            font-weight: bold;
            float: left;
            margin-left: 0.3rem;
            font-size: 14px;
          }
        }
      }

      .sizing-results-table , .show-more-rates-table {
        border-collapse: separate;
        border-spacing: 0;
        //overflow: auto;
        table-layout: auto;
        width: 100%;
        border-bottom-width: inherit;
        white-space: nowrap;
        background-color: #fff;

        tr {
          text-align: center;
          font-size: 14px;
        }
        td {
          padding: 8px;
        }
        th{
          border-bottom-width: inherit;        }
        .wrap {
          overflow-wrap: break-spaces;
        }
        .nowrap {
          overflow-wrap: inherit;
          white-space: inherit;
        }
        .header-content {
          display: inline-block;
          vertical-align: top;
          text-transform: uppercase;
        }
        .span_centered {
          position: relative;
        }

        tbody > tr > td:nth-child(1),
        thead > tr:nth-child(1) > th:nth-child(1),
        thead > tr:nth-child(2) > th:nth-child(1) {
          background-color: #fff;
          position: sticky;
          position: -webkit-sticky;
          left: 0;
          z-index: 2;
          border: 1px solid #ddd;
        }

        tbody > tr > td:nth-last-child(1) {
          padding: 3px;
        }

        .description-width {
          font-size: 11px;
          width: 260px;
          white-space: pre-wrap;
        }

        tbody > tr:hover > td:nth-child(1) {
          background-color: #ececec;
        }

        tbody > tr.selected-row > td:nth-child(1) {
          background-color: v.$background-grey2;
        }

        thead > tr:nth-child(1) > th:nth-child(1) {
          width: 100px;
        }

        .sub-text-uom {
          font-size: 11px;
          
        }
        .selected-row {
          background-color: v.$background-grey2;
        }
        thead > tr:nth-child(2) > th:nth-child(1),
        thead > tr:nth-child(2) > th {
          border-top: none;
          white-space: pre-wrap;

        }
      }
      .coriolis-table {
        thead > tr:nth-child(1) > th:nth-child(1),
        thead > tr:nth-child(1) > th:nth-child(2),
        thead > tr:nth-child(1) > th:nth-child(6),
        thead > tr:nth-child(1) > th:nth-child(7),
        thead > tr:nth-child(1) > th:nth-child(8) {
          border-bottom: none;
        }
      }
      .viscosity-table {
        thead > tr:nth-child(1) > th:nth-child(1),
        thead > tr:nth-child(1) > th:nth-child(2),
        thead > tr:nth-child(1) > th:nth-child(5),
        thead > tr:nth-child(1) > th:nth-child(6),
        thead > tr:nth-child(1) > th:nth-child(7) {
          border-bottom: none;
        }
      }
      .vortex-table {
        thead > tr:nth-child(1) > th:nth-child(1),
        thead > tr:nth-child(1) > th:nth-child(2),
        thead > tr:nth-child(1) > th:nth-child(4),
        thead > tr:nth-child(1) > th:nth-child(5),
        thead > tr:nth-child(1) > th:nth-child(6),
        thead > tr:nth-child(1) > th:nth-child(8),
        thead > tr:nth-child(1) > th:nth-child(9),
        thead > tr:nth-child(1) > th:nth-child(10),
        thead > tr:nth-child(1) > th:nth-child(11) {
          border-bottom: none;
        }
      }
      .mag-table {
        thead > tr:nth-child(1) > th:nth-child(1),
        thead > tr:nth-child(1) > th:nth-child(2),
        thead > tr:nth-child(1) > th:nth-child(3),
        thead > tr:nth-child(1) > th:nth-child(4),
        thead > tr:nth-child(1) > th:nth-child(5),
        thead > tr:nth-child(1) > th:nth-child(7),
        thead > tr:nth-child(1) > th:nth-child(8),
        thead > tr:nth-child(1) > th:nth-child(10) {
          border-bottom: none;
        }
      }
      .pagination-section {
        .pagination {
          font-size: 18px;
          font-weight: bold;

          .page-item {
            .page-link {
              color: #000;
              border: none;
            }

            .page-link:hover {
              color: v.$emerson-green;
              border: none;
              background-color: #fff;
              text-decoration: underline;
            }
          }

          .active {
            .page-link {
              background-color: #fff;
              font-weight: bold;
              color: v.$emerson-green;
            }
          }
        }
      }
    }
  }
  .MySizingBtn{
    font-size: 0.7rem;
    padding: 0.2rem;
    text-transform: uppercase;
  }
  .MySizingInner{
    border: 1px solid #767676;
    background-color: #efefef;
    width: 90px;
  }
  .MySizingInner:hover{
    border: 0px solid #767676;
    background-color: #c1c1c17a;
  }
  .fluidProp_float{
    float: left;
  }
  #units-max-pressure-drop, #units-pressure-atmospheric, #units-vapor-pressure,  #units-sonic-velocity{
    width: 158px; 
    float: left !important;
  }
  #units-density, .fluidProp_position, #units-viscosity, .fluid-max, .fluid-min, #units-gas-densitySG{
    position: relative;
    right: 0.5rem;
    
  }
  .font-weight-normal{
    text-align: center !important;
  }
  .fluid-unit{
    position: relative;
    right: 1rem;
  }
  .flow-unit{
    position: relative;
    right: 3.5rem;
  }
  #units-gas-temperature, #units-gas-pressure, #units-gas-density{
    margin-left: -0.8rem;
  }
  // #gas-pressure, #gas-temperature, #gas-density, #compressibility{
  //   border: 2px solid hsl(106, 100%, 30%);
  // }
  .table>thead>tr>th {
    border-bottom: 0;
  }
  .table-borderless td, .table-borderless thead {
    border: 0;
  }
  .dropdown .btn {
    text-transform: none;
  }
.operatingDropDown{
    font-size: 0.9rem;
    text-align: left;
    margin: 0;
    border-radius: 0;
    background-color: #f0f0f0;
    width: 160px;
    height: 30px;
    color: black;
    padding-left: 15px;
    padding-right: 5px;
    
    .dropdown-text {
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0;
      letter-spacing: 0.05px;
    }
    
}
.operatingDropDown:focus{
  background-color: #f0f0f0 !important;
  color: black !important;
}
.operatingDropDown:hover{
  background-color: #f0f0f0 !important;
  color: black !important;
}
.operatingDropDown:active{
  background-color: #f0f0f0 !important;
  color: black !important;
}
.dropdown-toggle {
  color: black !important;
  background-color: #f0f0f0 !important;
  //border-color: #07550b !important;
}
}

.row.mt-2.tech-guide {
  margin-left: 3px;
}

.rpv-thumbnail-list, .rpv-thumbnail-list-loader {
  display: block !important;
}
.rpv-default-layout-sidebar-content-opened {
  display: block !important;
}
.rpv-default-layout-sidebar-opened {
  width: 14rem !important;
}
/**/
.compare {
  
.show{
  display: block;
}

.hide {
  display: none;
}
}

#root .navbar {
  position: relative;
  padding: 0px 1rem;
  justify-content: end;
}

/* Tablet */
@media (max-width: 1024px) {
  .App {
    .sizing-and-selection {
      .basic-page {
        .container {
          max-width: 96%;
        }
        .right-nav-column {
          padding-left:0;
        }
        .right-nav-selection {
          .container {
            margin-left: 0;
            padding-left: 0;
            max-width: 100%;
        }
        }
      }
      .selection-container {
        max-width: 95%;
      }
    }
  }
}
@media (min-width:576px) and (max-width: 688px){
.operating-conditions{
  overflow-y: hidden;
  overflow-x: scroll;
  .dropdown-menu{
    max-height: 120px;
  }
}
.App{
    .dropdownMobile{
      display: flex;
      margin-left: -5px;
    }    
    .specificGravity{
      flex-direction: column;      
    }    
    .fluidProp_float{
      display: flex;
      flex-direction: column;
      margin-left: 8px;
    }
}
}
@media (min-width:200px) and (max-width: 576px){
  .operating-conditions{
    overflow-y: hidden;
    overflow-x: scroll;
    .dropdown-menu{
      max-height: 91px;
    }
    .App{
    .dropdownMobile{
      display: flex;
      margin-left: -5px;
    }    
    .specificGravity {
      flex-direction: column;
    }
    .fluidProp_float{
      display: flex;
      flex-direction: column;
      margin-left: 8px;
    }
}
  }
  .base-reference-conditions-table{
    margin-left: 2px;
  }
  }
@media (min-width:767px) and (max-width: 1024px){
  .operating-conditions{
    overflow-y: hidden;
    overflow-x: scroll;
    .dropdown-menu{
      max-height: 120px;
    }
  }
  .sizing-results-inputs-col {
    margin-right: 15px;
}
  }
/* Mobile */
@media (max-width: 767px) {
  .App {
    .sizing-and-selection {
      .basic-page {
        .container {
          max-width: 94%;
        }
      }
    }
    .dropdownMobile{
      display: flex;
      margin-left: -5px;
    }
  }

  
}

@media (max-width: 602px) {
  .App {
    .sizing-and-selection {
      .basic-page {
        .footer-nav-panel {
          // Gray bg cover for buttons
          height: 170px;   
          .sizing-results-button {
            // Basic page sizing button to align under next button
            margin: 10px 0 10px 10px
          }
        }
      }
    }
    .dropdownMobile{
      display: flex;
      margin-left: -5px;
    }
  }
}
@media (max-width: 602px) {
  .fluid-properties-table{
    overflow-y: visible !important;
    overflow-x: scroll;
  }
  .App{
      .dropdownMobile{
      display: flex;
      margin-left: -5px;
    }
  }
}

@media (min-width:540px) and (max-width: 600px){
  .sizing-results-inputs-col {
    input#sizing-results-application {
      margin-left: 30px;
      width: 250px;
   }
   
   input#sizing-results-service{
    margin-left: 55px;
    width: 250px;
   }

   input#sizing-results-project{
    margin-left: 13px;
    width: 250px;
   }
  }
}

/* Small Mobile */
@media (min-width:374px) and (max-width: 415px) {
  .fp-input {
    float: left;
    margin-right: 50px;
  }
  .App {
    .sizing-and-selection {
      .basic-page {
        .btn-nav {
          width: 125px;
        }
      }
    }
    .dropdownMobile{
      display: flex;
      margin-left: -5px;
    }
  }

  input#molecular-weight {
    margin-top: 10px;
    margin-left: 54px;
  }

  .sizing-results-inputs-col {
    input#sizing-results-application {
      margin-left: 30px;
      width: 200px;
   }
   
   input#sizing-results-service{
    margin-left: 55px;
    width: 200px;
   }

   input#sizing-results-project{
    margin-left: 13px;
    width: 200px;
   }
  }

  
}

@media(min-width:350px) and (max-width: 470px){
  .sizing-results-inputs-col {
    input#sizing-results-application {
      margin-left: 30px;
      width: 200px;
   }
   
   input#sizing-results-service{
    margin-left: 55px;
    width: 200px;
   }

   input#sizing-results-project{
    margin-left: 13px;
    width: 200px;
   }
  }
}

@media (max-width: 370px){
  
  .selection-container >form >.row > .container-fluid > .d-block {
    margin-left: -20px;
  }

  .App {
    .sizing-and-selection {
      .basic-page {
        .btn-nav {
          width: 125px;
        }
      }
    }
    .dropdownMobile{
      display: flex;
      margin-left: -20px;
      width: 135px ;
      .dropdown, .operatingDropDown{
        width: 135px ;
      }
    
      button#flow-rate-units {
        padding-left: 5px;
        padding-right: 10px;
        margin-left: -4px;
        width: 150px !important;
      }
    }
    
  }
  
  #units-density, #units-viscosity, #units-gas-densitySG{
    width: 120px;
  }

  #units-vapor-pressure, #units-max-pressure-drop, #units-sonic-velocity{
    width: 120px !important;
    margin-left: -3px;
  }
  
  #units-gas-pressure, #units-gas-temperature{
    width: 120px !important;
    margin-left: 2px !important;
  }

  #units-gas-density {
    width: 120px !important;
    margin-left: -70px !important;
  }

  #gas-density{
    margin-left: -70px !important;
  }
 
  #base-compressibility{
    margin-top: 35px;
    margin-left: -70px;
  }

  input#molecular-weight {
    margin-top: 10px;
    margin-left: 55px;
  }

  .sizing-results-inputs-col {
    display: flex;
    justify-content: space-between;
    
  }

}

.dropdownMobile{
  display: flex; 
  margin-top: -15px;  
}

