@use '../../styles/variables' as v;

.error-tooltip {
  .tooltip-inner {
    .title {
      text-align: center;
      font-weight: bold;
      font-size: 13px;
    }
    .left {
      text-align: left;
      float:none;
    }
    .alert-icon {
      float: left;
    }
  }  
}

.fontsize{
  font-size: 12px;
  font-weight: 500;
}

.tooltip {
  z-index: 999 !important;
}

// get rid of the default black arrow
.error-tooltip  .arrow::before {
  border-top: 5px solid v.$error-red !important;
  border-color:  v.$error-red transparent;
}
.alert-tooltip .arrow::after {
  border-top: 5px solid v.$emerson-black !important;
  border-color:  v.$emerson-black;
}

.error-tooltip  .tooltip-inner {
  max-width: 250px;
  background-color: v.$emerson-white;
  color: v.$error-red;
  border: 1px solid v.$error-red;
}



.alert-tooltip .tooltip-inner {
  background-color: v.$emerson-white;
  color: v.$emerson-black;
  border: 1px solid v.$emerson-black;
}