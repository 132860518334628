.report{
    padding-right: 102px;
    padding-left: 238px;
    width: auto;
} 
.btn-email {
    width: 144px;
    letter-spacing: 0.5px;
    border-radius: 0;
    height: 40px;
    font-size: 12px;
    margin: 9px 0 6px 0;
} 
.report-container {
    background-color: #fff;
    max-width: 1327px;
    // margin: 0px 1px 10px 20px;
     padding: 25px 15px 78px 15px;
    line-height: 14px;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
}
.update-report{
        padding-bottom:30px;
  

        font-weight: bold;
        font-size: 16px;
        margin: 0 0 20px 5px;
}
.btn-email-report {
   margin: 12px 2px 1px 484px;
    border-radius: 0;
    height: 50px;
    font-size: 12px;
    width: 230px;
    letter-spacing: 0.5px;
}
.dropdown-title-report {
    font-weight: bold;
    padding-left: 91px;
}
.quote-label {
    margin: 2px 0 10px 126px;
    font-weight: bolder;
}
.email-label {
    margin: 2px 0 10px 126px;
    font-weight: bolder;
}
#quote-inputfield {
    background-color: #dee2e6;
    border: 2px solid #c4ced1;
}
#email-inputfield {
    border: 2px solid rgb(26, 31, 29);
    background-color:#dee2e6;
    border: 2px solid #c4ced1;
}
.quote-fieldname {
    margin: -5px 0 10px 27px;
    width: 300px;
     
    }

.email-fieldname {
    margin: -5px 0 10px 27px;
    width: 300px;
}
.title-report {
    margin: 27px 103px 31px 500px;
    font-weight: bolder;
    font-size: 21px;
}
