@use '../../styles/variables' as v;

.btn.focus, .btn:focus,.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}

.bg-submit:hover {
  background-color: v.$emerson-green;
  border: 1px solid v.$emerson-green;
  color: white;
}
.bg-submit:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem #dc354500;
}
.bg-submit .right-arrow:after {
  content: "";
  margin-left: 6px;
  margin-right: 0;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  border: 0 solid white;
  border-right-width: 2px;
  border-bottom-width: 2px;
  display: inline-block;
  position: relative;
  transition: right ease 0.25s;
  right: 0;
  height: 8px;
  width: 8px;
  bottom: 1px;
}

.bg-submit:hover .right-arrow:after {
  right: -5px;
}

.btn-arrow:hover {
  background-color: v.$background-grey2;
  border: 1px solid v.$background-grey2;
  color: black;
}

.btn-primary {
  border: none;
}

.btn-nav {
  margin: 6px 6px 20px 6px;
  border-radius: 0;
  height: 50px;
  font-size: 12px;
  width: 155px;
  letter-spacing: 0.5px;
}

.btn-small-fpa {
  border-radius: 0;
  height: 28px;
  font-size: 12px;
  letter-spacing: 0.5px;
}

.btn-custom-gas {
  padding: 5px 25px;
  margin: 10px 5px;
}

@media (max-width: 767px) {
  .btn-custom-gas {
    padding: 0px;
  }

  .btn-white-fpa.btn.btn-outline-dark.btn-sm {
    height: 60px;
  }

  button.btn.btn-small-fpa.btn-arrow.btn-custom-gas.btn-white-fpa.btn.btn-outline-dark.btn-sm {
    height: 60px;
  }

}

@media (max-width: 280px) {
  button.btn.btn-nav.btn-arrow.btn-white-fpa.btn.btn-outline-dark.btn-lg {
    width: 90px;
    height: 40px;
  }

  .App .header-sticky .sizing-and-selection-header .button-nav, .App .header .sizing-and-selection-header .button-nav {
    width: 172%;
  }

  .button-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  
    button.btn.btn-sizing-nav.btn-arrow.btn-compare.btn-white-fpa.btn.btn-outline-dark.btn-lg {
      width: 100%;
    }

    .proceed-button-col-section{
      width: 100%;
      padding-left: 0px;
      .proceed-button-section {
        margin-left: 0px;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        
        .customButton{
          width: 95%;
          .btn-sizing-nav-rates{
            padding: 0;
            width: 100%;
          }

          .btn-sizing-nav-graph {
            padding: 0;
            
            width: 100%;
          }
        }

        .btn-green{
          justify-content: flex-end;
          height: 42px;
          width: 100%;
        }
      }
    }
  }


}

.button-row {
  margin-top: 10px;
  margin-left: 8%;
  
  .btn-sizing-nav {
    margin: 10px 6px 10px 6px;
    border-radius: 0;
    height: 50px;
    font-size: 12px;
    width: 135px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }
  .btn-compare {
    margin-left: 0;
    display: inline-block;
  }
 

  .proceed-button-section {
    float: right;
    width: 627px;
  }
  .viewGraph {
    float: right;  
  }
  .proceed-button-col-section {
    margin-right: 0;
    padding-right: 0;
    float: left;
  }
  .btn-sizing-nav-rates {
    width: 180px;
  }
  .btn-generate-report{
    width: 211px;
    
  }
  .btn-export-graph{
    width: 140px;
  }
}

@media (max-width: 900px) {
  .button-row {
    display: flex;
    flex-direction: column;
    margin-left: 0px;
    flex-wrap: wrap;
    justify-content: space-between;
  
    .compare{
      justify-content: center;

      .BtnFloat{
        width: 100%;
        .customButton{
          display: inline-block;
          height: 65px;
          width: 100%;
          .btn-compare {
            width: 150px;
            padding: 0px;
          }
        }
      }
    } 

    .proceed-button-col-section{
      width: 100%;
      justify-content: center;

      .proceed-button-section {
       width: 100%;

        .customButton{
          .btn-sizing-nav-rates{
            width: 75%;
          }

          .btn-sizing-nav-graph {
            width: 75%;
          }

          .btn-compare{
            width: 75%;
          }
        }

        .btn-green{
          width: 75%;
        }


      }

      .viewGraph{
        justify-content: center;
        .btn-generate-report{
          width: 95%;
        }

        button.btn.btn-sizing-nav.btn-export-graph.btn-white-fpa.btn.btn-outline-dark.btn-lg {
          width: 95%;
        }
        button.btn.btn-sizing-nav.btn-sizing-nav-proceed.btn-green.btn.btn-primary.btn-lg {
          width: 95%;
        }
        
      }
      
    }
  }

 


}

 



.btn-close-modal {
  margin: 6px 6px 6px 6px;
  border-radius: 0;
  height: 50px;
  font-size: 12px;
  width: 135px;
  letter-spacing: 0.5px;
}
.btn-configure-modal {
  margin: 6px 6px 6px 6px;
  border-radius: 0;
  height: 50px;
  font-size: 12px;
  width: 217px;
  letter-spacing: 0.5px;
}
.btn-my-sizings {
  width: 100px;
  letter-spacing: 0.5px;
  border-radius: 0;
  height: 40px;
  font-size: 12px;
  margin: 9px 0 6px 0;
}
.btn-my-sizings.btn-search {
  margin-left: 30px;
}

.btn-converter {
  width: 100px;
  letter-spacing: 0.5px;
  border-radius: 0;
  height: 50px;
  font-size: 12px;
  display: inline-block;
}

.btn-save-prefs {
  margin: 6px 6px 6px 6px;
  border-radius: 0;
  height: 50px;
  font-size: 12px;
  width: 230px;
  letter-spacing: 0.5px;
}

.btn-corrosion-guide {
  // todo: high margin to be changed when we have side nav done
  margin: 5px 15px 5px;
  border-radius: 0;
  width: 180px;
  height: 40px;
  letter-spacing: 0.5px;
  font-size: 12px;
  outline: none;
}

.btn-product-family-comparisons {
  // todo: high margin to be changed when we have side nav done
  margin: 10px 15px 5px;
  padding: 0px 0px 0px 5px;
  border-radius: 0;
  width: 180px;
  height: 40px;
  letter-spacing: 0.5px;
  font-size: 12px;
  outline: none;
}

 .btn-white-fpa {
  background-color: v.$emerson-white;
  border: 1px solid black;
  color: black;
  outline: none;
  box-shadow: none;
}
.btn-white-fpa:hover,
.btn-white-fpa:focus,
.btn-white-fpa:active{
  outline: none;
  background-color: #dee6ea !important;
  border: 1px solid #dee6ea !important;
  color: black !important;
  box-shadow: none !important;
}

.btn-green {
  background-color: v.$emerson-green !important;
}

.btn-green:hover,
.btn-green:after {
  color: white;
  background-color: darken(v.$emerson-green, 5%) !important;
}
.bg-submit {
  background-color: v.$emerson-green;
  color: white;
}
.bg-submit .btn-arrow .right-arrow:after {
  border-color: white !important;
}
.bg-submit .btn-arrow:hover {
  background-color: v.$emerson-green !important;
  border: 1px solid v.$emerson-green !important;
  color: white !important;
}
.bg-submit .btn-arrow .right-arrow:after {
  border: 0 solid white !important;
}
.btn-arrow .right-arrow:after {
  content: "";
  margin-left: 6px;
  margin-right: 0;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  border: 0 solid black;
  border-right-width: 2px;
  border-bottom-width: 2px;
  display: inline-block;
  position: relative;
  transition: right ease 0.25s;
  right: 0;
  height: 8px;
  width: 8px;
  bottom: 1px;
}

.btn-arrow .left-arrow:after {
  content: "";
  margin-left: 6px;
  margin-right: 0;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
  border: 0 solid black;
  border-right-width: 2px;
  border-bottom-width: 2px;
  display: inline-block;
  position: relative;
  transition: left ease 0.25s;
  right: 0;
  height: 8px;
  width: 8px;
  bottom: 1px;
}

.btn-arrow .left-arrow-nav:after {
  content: "";
  margin-left: 6px;
  margin-right: 0;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
  border: 0 solid white;
  border-right-width: 2px;
  border-bottom-width: 2px;
  display: inline-block;
  position: relative;
  -webkit-transition-property: left;
  -webkit-transition-timing-function: ease;
  -webkit-transition-duration: 0.25s;
  transition-property: left;
  transition-duration: 0.25s;
  transition-timing-function: ease;
  right: 0;
  height: 8px;
  width: 8px;
  bottom: 1px;
}

.btn-arrow:hover .right-arrow:after {
  right: -5px;
}

.btn-arrow:hover .left-arrow:after,
.btn-arrow:hover .left-arrow-nav:after {
  left: -5px;
}

.btn-compose-natual-gas {
  @media (min-width: 300px)and (max-width:576px) {
    margin-top: 7px;
    margin-left: 10px;
  }
  border-radius: 0;
  height: 50px;
  font-size: 12px;
  width: 140px;
  letter-spacing: 0.5px;
  background-color: v.$emerson-green !important;
  border: none;
  margin-top: 34px;
}

.back-button-text {
  padding-top: 17px;
}

//.btn-compose-natual-gas.active,
.btn-compose-natual-gas:hover,
.btn-compose-natual-gas:after {
  background-color: darken(v.$emerson-green, 5%) !important;
}

.btn-modal {
  border-radius: 0;
  letter-spacing: 0.5px;
  font-size: 12px;
  width: 100px;
  flex: auto;
  margin-left: 10px;
  margin-right: 10px;
}

.btn-delete-select-modal {
  background-color: v.$emerson-green;
}

.btn-delete-select-modal:hover,
.btn-delete-select-modal:after {
  background-color: darken(v.$emerson-green, 5%) !important;
}

.btn-component-select-arrow-right,
.btn-component-select-arrow-left {
  @media (max-width: 767px) {
    width: 100%
  }
  @media (min-width: 300px)and (max-width:576px) {
    width: 22%
  }
  width: 90px;
  border-radius: 0;
}

.btn-component-select-arrow-right {
  margin-top: 200px;
  @media (min-width: 576px)and (max-width:1024px) {
    width:100%
  }
  @media (min-width: 300px)and (max-width:576px) {
    margin-top: 34px;
  }
}

.btn-component-select-arrow-left {
  margin-top: 25px;
  @media (min-width: 576px)and (max-width:1024px) {
    width:100%
  }
}
