@use '../../styles/variables' as v;

.download-page{
    margin: 25px;
}
.emerson-logo{
    padding-left: 20px;
}
.main-report{
    margin-right: 55px;
    background-color: v.$background-grey;
    .due-date{
        font-size: 25px;
        font-family:  Arial, serif;
        color: v.$error-red;
        margin-left: 15px;
    }

}



.download-btn{
    background-color: white;
    min-width: 210px;
    align-content: right;
}

.download-report{
    font-family: Arial, serif;
    color:  v.$emerson-blue;    
    font-size: 30px;
    .icon-download{
        width: 20px;
    }    
}

.footer{
    font-family: Arial, serif;
    background-color: v.$background-grey;
    margin-top: 35px;
    border: 1px solid black;
    padding: 10px;
    margin-right: 55px;
    .footer-main-text{
        font-size: 20px;
        .text-left{
            margin-top: 10px;
            padding-left: 10px;
            font-size: 18px;
        }
    }
}

.footer-due{
    font-family: Arial, serif;
    background-color: v.$emerson-white;
    margin-top: 35px;
    padding: 10px;
    margin-right: 55px;
    .footer-main-text{
        font-size: 20px;
        .text-left{
            margin-top: 10px;
            padding-left: 10px;
            font-size: 18px;
        }
    }
}




