@use '../../styles/variables' as v;

label.custom-radiobutton-label {
  line-height: 18px;
}

input[type=radio]:empty {
  display: none;
}

input[type=radio]:empty + label {
  position: relative;
  // float: left;
  text-indent: 0;
  padding-left: 20px;
  cursor: pointer;
}

input[type=radio]:empty + label:before {
  position: absolute;
  top: 2px;
  bottom: 0;
  left: 0;
  content: '';
  display: inline-block;
  border-radius: 100%;
  width: 13px;
  height: 13px;
  border: 1px solid #c0c0c0;
}

input[type=radio]:hover:enabled:not(:checked) + label:before {
  background-color: grey;
}

input[type=radio]:checked + label:before {
  content: '';
  border-radius: 100%;
  width: 13px;
  height: 13px;
  background-color: v.$emerson-green;
  position: absolute;
  left: 0;
  top: 2px;
}

input[type=radio]:disabled + label {
  color: #b8b8b8;
}
