@use '../../styles/variables' as v;
.clearAll{
  position: relative;
  color: v.$emerson-green;
  font-size: .75em;
  cursor: pointer;
  white-space: nowrap;
}

.selectednameshow {
  margin-right: 8px;
  display: inline-block;
  background-color: v.$emerson-green;
  color: v.$emerson-white;
  padding: 4px 10px;
  margin-bottom: 10px;
  font-size: .75em;
  cursor: pointer;
}

.CheckBoxList{
  font-size: 0.875rem;
  color: v.$CheckBoxList;
  display: flex;
  margin-bottom: 0.4rem;
}
.checkBoxLabel{
  margin-left: 0.4rem;
  margin-top: -0.2rem;
}

.CheckBoxList label:hover{
  color: v.$emerson-green;
  text-decoration: underline;
  cursor: pointer;
}

.closeFilter:before {
  content: 'x';
  opacity: 1;
  margin-left: 10px;
  text-shadow: none;
  color: v.$emerson-white;
  line-height: 1.5em;
  font-size: 0.8rem;
  font-weight: 800;
  position: relative;
  top: 0rem;
}

.SizingMargin{
  margin-bottom: 1rem;
}


.isRotated svg {
  transform: rotate(-900deg);
}

.click-layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.card {
  margin-top: 0.5rem;
  border: 0;
  border-bottom: 1px solid rgba(0,0,0,.125);
}
.BorderFilter{
  border-bottom: 1px solid rgba(0,0,0,.125);
}
.CollapsibleChild{
  font-size: .875em;
    font-weight: bold;
    text-transform: uppercase;
}

.CollapsibleParent{
  font-weight: 600;
  font-size: .75em;
  letter-spacing: .08em;
}

.FilterHeight{
  max-height: 578px;
  overflow-y: auto;
}

.BtnFloat{
  float: left;
}
.filterSubText{
  font-size: 11px;
  font-weight: bold;
  text-transform: uppercase;
}

.processTable { tr {
  border: 0px ​solid transparent;
}
}

.fullDesign{
  text-align: center;
}
.processUnits{
  font-size: 0.8rem;
  font-weight: normal;
}
.loader {
  height: 500px;
} 