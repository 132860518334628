@use '../../styles/variables' as v;

.toggle-switch {
  position: relative;
  width: 100px;
  display: inline-block;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  text-align: left;
}
.toggle-switch-checkbox {
  display: none;
}
.toggle-switch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 0 solid #ccc;
  border-radius: 20px;
  margin: 0;
}
.toggle-switch-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}
.toggle-switch-inner::before,
.toggle-switch-inner::after {
  display: block;
  float: left;
  width: 50%;
  height: 30px;
  padding: 0;
  line-height: 30px;
  font-size: 10px;
  color: #fff;
  font-weight: bold;
  box-sizing: border-box;
}

.toggle-switch-inner::before {
  content: attr(data-yes);
  text-transform: uppercase;
  padding-left: 10px;
  background-color: v.$emerson-blue;
  color: #fff;
}
.toggle-switch-inner::after {
  content: attr(data-no);
  text-transform: uppercase;
  padding-right: 10px;
  background-color: v.$emerson-green;
  color: #fff;
  text-align: right;
}
.toggle-switch-switch {
  display: block;
  width: 24px;
  margin: 5px;
  background: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 65px;
  border: 0 solid #ccc;
  border-radius: 20px;
  transition: all 0.3s ease-in 0s;
}
.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-inner {
  margin-left: 0;
}
.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-switch {
  right: 0;
}
