.showMoreBorder{
    border: 1px solid #c4ced1;
    border-radius: 2px;
    margin-top: 18px;
    margin-left: 0px;
    margin-bottom: 16px;
}
.showMorePadding{
    padding-top: 10px;
    font-weight: 400;
    font-size: 14px;
    max-width: 91%;
    color: #000;
}

.Labelpadding{
    padding-left: 0px;
}
.ShowMoreHeader{
    font-size: 21px;
    text-transform: capitalize;
}

.showMoreSubHeader{
    font-weight: 600;
    font-size: 14px;
}
.imageRight{
    margin-top: -40px;
    margin-left: 150px ;
    position: relative;
}